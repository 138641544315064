import "../styles/index.scss";
// import "../components/sidebar";

// import * as mdb from 'mdb-ui-kit/js/mdb.es.min.js';
import * as mdb from 'mdb-ui-kit/js/mdb.umd.min.js';
import "mdb-ui-kit/js/chart.es.min.js";
window.mdb = mdb;
window.document.addEventListener("DOMContentLoaded", function () {
  window.console.log("DOM Ready");
});
window.document.addEventListener("click", function () {
  let elements = document.getElementsByClassName('hamburger-button-videos');
  let hamburgerButtonCheck = elements[0];
  if (hamburgerButtonCheck === null || hamburgerButtonCheck === undefined) {
    return;
  }
  if (hamburgerButtonCheck.classList.contains('hamburger-button-videos')) {
    const hamburger_button = document.getElementById('hamburger_button');
    const navbar_logo = document.getElementById('navbar_logo');
    const sidebar_state = hamburger_button.getAttribute('aria-expanded');
    if (sidebar_state == "true") {
      hamburger_button.style.display = "none";
      navbar_logo.style.display = "none";
    } else {
      hamburger_button.style.display = "block";
      navbar_logo.style.display = "block";
    }
  }
});